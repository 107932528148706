@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-mix-shadow {
  @apply shadow-custom-1 shadow-custom-2 shadow-custom-3;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 240 92% 71%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 43%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 240 17% 95%;
    --ring: 240 16% 91%;
    --radius: 0.5rem;
  }

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  @font-face {
    font-family: 'HanwhaWindow';
    font-style: normal;
    font-weight: 100 200;
    src: url('../public/assets/fonts/03HanwhaL.ttf') format('truetype');
  }
  @font-face {
    font-family: 'HanwhaWindow';
    font-style: normal;
    font-weight: 300 400;
    src: url('../public/assets/fonts/03HanwhaL.ttf') format('truetype');
  }
  @font-face {
    font-family: 'HanwhaWindow';
    font-style: normal;
    font-weight: 500 700;
    src: url('../public/assets/fonts/03HanwhaL.ttf') format('truetype');
  }

  @font-face {
    font-family: 'HanwhaMac';
    font-style: normal;
    font-weight: 100 200;
    src: url('../public/assets/fonts/03HanwhaL_OTF.otf') format('opentype');
  }
  @font-face {
    font-family: 'HanwhaMac';
    font-style: normal;
    font-weight: 300 400;
    src: url('../public/assets/fonts/03HanwhaL_OTF.otf') format('opentype');
  }
  @font-face {
    font-family: 'HanwhaMac';
    font-style: normal;
    font-weight: 500 700;
    src: url('../public/assets/fonts/03HanwhaL_OTF.otf') format('opentype');
  }

  html {
    font-family: 'Pretendard', sans-serif, 'Montserrat';
    color: #111111;
    letter-spacing: -0.025em;
    margin: 0;
    padding: 0;
  }

  body {
    @apply text-sm;
    margin: 0;
    padding: 0;
  }
}
#root {
  height: 100dvh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.no-resize {
  resize: none;
}

.position-center {
  position: absolute;
  z-index: 2100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-x-center {
  position: absolute;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
}

.position-y-center {
  position: absolute;
  z-index: 2100;
  top: 50%;
  transform: translateY(-50%);
}

.ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scroll-hidden::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.scroll-hidden {
  scrollbar-width: none;
}

.main-banner1-style {
  border-radius: 17px;
  background: linear-gradient(81deg, #edf0ff 24.86%, #7c7dff 77.27%);
}

/** datepicker 기본 스타일 초기화 */
.datepicker-modal {
  position: fixed !important;
  background-color: transparent !important;
  animation: fadeIn 150ms ease;
  min-height: 220px;
  height: 28vh !important;
  overflow-y: hidden !important;
  z-index: 100000 !important;
  bottom: 80px !important;
}
.datepicker-modal .datepicker.default {
  background-color: transparent;
  position: absolute;
  min-height: 200px;
  height: 23.6vh;
  bottom: 3.5vh;
}
.datepicker-modal .datepicker-content {
  width: 100%;
  padding: 0 6.97vw !important;
  position: absolute;
  bottom: 0;
  min-height: 200px;
  height: 23.6vh;
  gap: 15px;
  align-items: center;
}
.datepicker.default .datepicker-scroll li {
  background-color: transparent;
}
.datepicker-modal .datepicker-navbar {
  display: none;
}

.datepicker.default .datepicker-wheel {
  border-top: none !important;
  border-bottom: 1px solid #ddd !important;
}
.datepicker.default .datepicker-wheel .datepicker-scroll > li {
  font-family:
    'Noto Sans KR',
    'NotoSansKR',
    '나눔스퀘어OTF',
    -apple-system,
    BlinkMacSystemFont;
  color: #333;
  font-size: 16px;
  font-weight: 350;
  background-color: transparent;
}
.datepicker.default .datepicker-wheel .datepicker-scroll > li:nth-child(6) {
  font-size: 18px !important;
  background-color: transparent;
  font-weight: 500;
}
@keyframes fadeIn {
  0% {
    transform: translateY(-35.5vh);
    opacity: 0;
  }
  50% {
    opacity: 0.7;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.datepicker.default .datepicker-viewport::after {
  background: linear-gradient(
    #ffffff,
    rgba(245, 245, 245, 0) 52%,
    rgba(245, 245, 245, 0) 48%,
    #ffffff
  ) !important;
  animation: fadeIn 150ms ease;
}

// 메인페이지 - 상단 배너 및 주문 들어가는 영역 스타일
// 모바일 웹(scss 사용중)에서 작업한 내용을 옮겨 넣었기 때문에 이 부분을 scss로 작성합니다.
.main-swiper {
  width: 100%;
  min-height: 450px;
  padding: 16px 0px 0px 0px;
  position: relative;
  &__swiper {
    & > .swiper {
      .swiper-wrapper {
        .swiper-slide {
          width: 85%;
          height: 180px;
          transition: all 0.3s ease;
          &.swiper-slide-prev,
          &.swiper-slide-next {
            transform: scale(0.9, 0.85);
            scale: (0.9, 0.85);
          }
          &.swiper-slide-active {
            transform: scale(1);
          }
          & > div {
            border-radius: 12px;
            width: 100%;
            height: 100%;
            padding: 24px;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center !important;
            overflow: hidden;
            &.swiper__type01 {
              position: relative;
              display: flex;
              align-items: center;
              & > div {
                width: 100%;
                height: 100%;
                cursor: pointer;
                & > span {
                  color: #fff;
                  font-size: 12px;
                  line-height: 18px;
                  margin-bottom: 4px;
                  cursor: pointer;
                }
                & > h3 {
                  position: relative;
                  color: #fff;
                  font-size: 20px;
                  line-height: 28px;
                  margin-bottom: 12px;
                  cursor: pointer;
                  z-index: 1;
                  & > span {
                    display: block;
                    cursor: pointer;
                  }
                }
                & > p {
                  font-size: 13px;
                  line-height: 18px;
                  color: #fff;
                  cursor: pointer;
                }
              }
              & > img {
                position: absolute;
                width: 64px;
                height: 64px;
                top: -20px;
                bottom: 0;
                margin: auto 0;
                right: 5px;
                cursor: pointer;
                z-index: 0;
              }
            }
            &.swiper__type02 {
              & > div {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                & > img {
                  position: absolute;
                  bottom: 0px;
                  right: -8px;
                  width: 128px;
                  height: 115px;
                  object-fit: contain;
                  z-index: 0;
                }
                & > h4 {
                  position: absolute;
                  bottom: 0px;
                  left: 0;
                }
                & > div {
                  width: auto;
                  height: auto;
                  position: relative;
                  display: inline-block;
                  text-align: left;
                  padding-top: 16px;
                  & > strong {
                    display: none;
                  }
                  & > h3 {
                    position: relative;
                    font-size: 24px;
                    line-height: 27px;
                    font-weight: 400;
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    z-index: 1;
                    color: #fff;
                    & > span {
                      display: inline-block;
                      font-family: 'Times New Roman' !important ;
                    }
                  }
                  & > span:nth-of-type(1) {
                    position: absolute;
                    right: -12px;
                    bottom: -3px;
                    z-index: 0;
                  }
                  & > .star-effect {
                    width: 29px;
                    height: 32px;
                    & > img {
                      width: 100%;
                      object-fit: contain;
                    }
                    position: absolute;
                    top: 5px;
                    right: -24px;
                  }
                }
              }
            }
            &.swiper__type03 {
              display: flex;
              background-color: #4a40b9 !important;
              background: radial-gradient(
                100% 70% at 30% 10%,
                rgba(255, 255, 255, 0.4) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              backdrop-filter: blur(42px) brightness(100%);
              & > div {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                & > img {
                  position: absolute;
                  bottom: -50px;
                  left: 0px;
                  right: 0px;
                  margin: 0 auto;
                  width: 190px;
                  height: 111px;
                  object-fit: contain;
                }
                & > div {
                  width: auto;
                  height: auto;
                  position: relative;
                  display: block;
                  text-align: center;
                  & > strong {
                    display: none;
                  }
                  & > h3 {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    z-index: 1;
                    color: #fff;
                    & > strong {
                      display: block;
                      font-family: 'Times New Roman' !important ;
                      font-size: 24px;
                      font-weight: 400;
                      line-height: 27.6px;
                    }
                    & > span {
                      display: block;
                      font-family: 'Times New Roman' !important ;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 18.4px;
                    }
                  }
                }
              }
            }
            &.swiper__type04 {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              gap: 12px;
              & > h3 {
                width: 95.8px;
                height: 16px;
                & > svg {
                  width: 100%;
                  object-fit: contain;
                }
              }
              & > p {
                font-size: 20px;
                line-height: 28px;
              }
              & > button {
                color: #fff;
                border-radius: 100px;
                font-size: 13px;
                line-height: 18px;
                & > div {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 5px;
                  & > span {
                    width: 35px;
                    height: 35px;
                    background-color: #ffffff99;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    & > img {
                      width: 50%;
                      object-fit: contain;
                    }
                  }
                }
                & > img {
                  width: 56px;
                  height: 56px;
                  object-fit: contain;
                }
              }
            }
            &.swiper__type05 {
              background-color: #111111;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              gap: 12px;
              padding: 32px 0px;
              & > h3 {
                width: 95.8px;
                height: 16px;
                & > svg {
                  width: 100%;
                  object-fit: contain;
                }
              }
              & > p {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 12px;
                color: #fff;
              }
              & > button {
                color: #fff;
                padding: 9px 16px;
                border-radius: 100px;
                border: 1px solid #fff;
                font-size: 13px;
                line-height: 18px;
                position: relative;
                z-index: 1;
                background-color: transparent;
              }
              & > img {
                width: 118px;
                height: 117px;
                object-fit: contain;
                position: absolute;
                bottom: -10px;
                right: 0;
                z-index: 0;
              }
            }

            // &.swiper__type01 {
            //   position: relative;
            //   & > div {
            //     width: 100%;
            //     height: 100%;
            //     & > span {
            //       color: #fff;
            //       font-size: 12px;
            //       line-height: 18px;
            //       margin-bottom: 4px;
            //     }
            //     & > h3 {
            //       color: #fff;
            //       font-size: 20px;
            //       line-height: 28px;
            //       margin-bottom: 12px;
            //       & > span {
            //         display: block;
            //       }
            //     }
            //     & > p {
            //       font-size: 13px;
            //       line-height: 18px;
            //       color: #fff;
            //     }
            //   }
            //   & > img {
            //     position: absolute;
            //     width: 64px;
            //     height: 64px;
            //     top: -45px;
            //     bottom: 0;
            //     margin: auto 0;
            //     right: 5px;
            //   }
            // }
            // &.swiper__type02 {
            //   display: flex;
            //   flex-direction: column;
            //   justify-content: space-between;
            //   align-items: center;
            //   gap: 12px;
            //   & > h3 {
            //     width: 95.8px;
            //     height: 16px;
            //     & > svg {
            //       width: 100%;
            //       object-fit: contain;
            //     }
            //   }
            //   & > p {
            //     font-size: 20px;
            //     line-height: 28px;
            //     margin-bottom: 12px;
            //     color: #fff;
            //     padding: 1px 12px;
            //     border-radius: 15px;
            //     background-color: rgba(12, 12, 12, 0.4);
            //   }
            //   & > button {
            //     color: #fff;
            //     padding: 9px 16px;
            //     border-radius: 100px;
            //     border: 1px solid #fff;
            //     font-size: 13px;
            //     line-height: 18px;
            //   }
            //   & > button:active {
            //     background-color: rgba(255, 255, 255, 0.05);
            //   }
            // }
            // &.swiper__type03 {
            //   display: flex;
            //   flex-direction: column;
            //   justify-content: space-between;
            //   align-items: center;
            //   gap: 12px;
            //   & > h3 {
            //     width: 95.8px;
            //     height: 16px;
            //     & > svg {
            //       width: 100%;
            //       object-fit: contain;
            //     }
            //   }
            //   & > p {
            //     font-size: 20px;
            //     line-height: 28px;
            //   }
            //   & > button {
            //     color: #fff;
            //     border-radius: 100px;
            //     font-size: 13px;
            //     line-height: 18px;
            //     & > div {
            //       display: flex;
            //       flex-direction: column;
            //       justify-content: center;
            //       align-items: center;
            //       gap: 5px;
            //       & > span {
            //         width: 35px;
            //         height: 35px;
            //         background-color: #ffffff99;
            //         border-radius: 100%;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         cursor: pointer;
            //         & > img {
            //           width: 50%;
            //           object-fit: contain;
            //         }
            //       }
            //     }
            //     & > img {
            //       width: 56px;
            //       height: 56px;
            //       object-fit: contain;
            //     }
            //   }
            // }
          }
        }
      }
    }
    & > .pagination {
      position: absolute;
      padding: 5px 12px;
      top: 220px;
      width: 96px;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #00000099;
      border-radius: 100px;
      .swiper-button-prev {
        position: relative;
        top: 0;
        margin: 0;
        left: 0;
        width: 12px;
        height: 12px;
        color: #fff;
        &::after {
          font-size: 12px;
          font-weight: 600;
        }
      }
      .swiper-pagination {
        position: relative;
        color: #ffffff99;
        font-size: 12px;
        font-weight: 600;
        & > span {
          color: #fff;
        }
      }
      .swiper-button-next {
        position: relative;
        top: 0;
        right: 0;
        margin: 0;
        width: 12px;
        height: 12px;
        color: #fff;
        &::after {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
  &__menu {
    position: absolute;
    width: 100%;
    height: 311px;
    bottom: 16px;
    padding-bottom: 16px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    &.bgColor-1 {
      background: linear-gradient(
        180deg,
        rgba(62, 203, 180, 0.6) 3.34%,
        rgba(255, 255, 255, 0.1) 55.63%
      );
    }
    &.bgColor-2 {
      background: linear-gradient(
        180deg,
        rgba(74, 64, 185, 0.6) 3.34%,
        rgba(255, 255, 255, 0.1) 55.63%
      );
    }
    &.bgColor-3 {
      background: linear-gradient(
        180deg,
        rgba(128, 121, 206, 1) 3.34%,
        rgba(255, 255, 255, 0.1) 55.63%
      );
    }
    &.bgColor-4 {
      background: linear-gradient(
        180deg,
        rgba(255, 214, 0, 0.6) 3.15%,
        rgba(255, 255, 255, 0.1) 42.44%
      );
    }
    &.bgColor-5 {
      background: linear-gradient(
        180deg,
        rgba(46, 80, 109, 0.6) 3.34%,
        rgba(255, 255, 255, 0.1) 55.63%
      );
    }
    & > div {
      width: 100%;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > h2 {
        width: 100%;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
      }
      & > ul {
        width: 100%;
        padding: 0 9px;
        display: flex;
        justify-content: space-between;
        & > li {
          &:first-child {
            & > div {
              background-color: #7273f933;
              border: 1px solid #7273f933;
            }
          }
          &:nth-child(2) {
            & > div {
              background-color: #2b3e7f33;
              border: 1px solid #2b3e7f33;
            }
          }
          &:nth-child(3) {
            & > div {
              background-color: #ffd14233;
              border: 1px solid #ffd14233;
            }
          }
          &:last-child {
            & > div {
              background-color: #b9d9f933;
              border: 1px solid #b9d9f933;
            }
          }
          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            padding: 10px;
            border-radius: 100%;
            margin-bottom: 12px;
            & > img {
              width: 28px;
              height: 28px;
              object-fit: contain;
            }
          }
          & > p {
            font-size: 13px;
            line-height: 18px;
            text-align: center;
          }
        }
      }
    }
  }
}

// 카카오맵 관련 이동 허용
.kakaoMapContainer img {
  pointer-events: auto !important;
}

.audio-visualizer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 15px;
  width: 70px;
}
.audio-visualizer li {
  list-style: none;
  background: #111;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  animation: bounce 1s infinite ease-in-out;
  animation-play-state: paused;
}
.audio-visualizer.playing li {
  animation-play-state: running;
}
.audio-visualizer li:nth-child(1) {
  animation-delay: -0.4s;
}

.audio-visualizer li:nth-child(2) {
  animation-delay: -0.2s;
}

.audio-visualizer li:nth-child(3) {
  animation-delay: 0s;
}

.audio-visualizer li:nth-child(4) {
  animation-delay: 0.2s;
}

.audio-visualizer li:nth-child(5) {
  animation-delay: 0.4s;
}

// audio-visualizer에서 사용중
@keyframes bounce {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}

/** 카사요 웹 - 모바일과 동일한 스타일  */
// 캐릭터스토리 > section01에서 적용중
@keyframes twinkle {
  0% {
    opacity: 0.8;
    transform: scale(1) rotate(1deg);
  }

  50% {
    opacity: 0;
    transform: scale(0.8) rotate(-5deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(2deg);
  }
}
@keyframes smallScrollDown {
  0% {
    opacity: 1;
    transform: translateY(-40px);
    height: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(40px);
    height: 60px;
  }
}
// 캐릭터 스토리 상세 페이지에서 사용
@keyframes fadeIn {
  0% {
    opacity: 0.5;
    z-index: -999;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    z-index: 100;
    transform: translateX(0px);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    z-index: 100;
    transform: translateX(0px);
  }
  100% {
    opacity: 0.5;
    z-index: -999;
    transform: translateX(-50px);
  }
}
// AD형 자동차 보험 banner
.ad-banner {
  background-color: #fff;
  width: 100%;

  & > div {
    width: calc(100% - 32px);
    margin: 0 auto;
    min-height: 295px;
    box-shadow: 0 12px 32px 0 rgba(29, 32, 38, 0.1);
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    & > h4 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: #096bff;
      cursor: pointer;
      text-align: center;
    }
    & > button {
      width: 100%;
      padding: 14px 16px;
      background-color: #096bff;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.4px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      gap: 32px;
      & > img {
        width: 190px;
        object-fit: contain;
        cursor: pointer;
      }
      & > p {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
.m-content {
  /** 캐릭터 페이지 */
  .m-character01 {
    padding-top: 20px;
    background-color: #4a40b9;
    display: flex;
    flex-direction: column;
    & > div:first-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px 16px 40px;
      & > div {
        position: relative;
        display: inline-block;
        text-align: left;
        & > strong {
          display: none;
        }
        & > h3 {
          position: relative;
          font-size: 48px;
          font-weight: 400;
          line-height: 55.2px;
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          z-index: 1;
          color: #fff;
          & > span {
            position: relative;
            display: inline-block;
            z-index: 1;
            font-family: 'Times New Roman' !important ;
          }
        }
        & > span:nth-of-type(1) {
          position: absolute;
          right: -18%;
          bottom: -5px;
          z-index: 0;
          transform-origin: bottom left;
        }
        & > .star-effect {
          width: 45px;
          height: 49px;
          & > img {
            width: 100%;
            object-fit: contain;
          }
          position: absolute;
          top: 0px;
          right: -25px;
          animation: twinkle 0.7s ease-in-out infinite;
        }
      }
    }
    & > div:nth-of-type(2) {
      padding: 40px 16px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 16px;
      & > p {
        color: #fff;
        & > span {
          font-size: 16px;
          line-height: 24px;
          display: block;
          letter-spacing: -0.4px;
        }
      }
    }
    & > div:last-of-type {
      position: relative;
      width: 100%;
      min-height: 110px;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 40px;
        width: 1px;
        height: 60px;
        background-color: #fff;
        animation: smallScrollDown 2s infinite ease-in-out;
      }
      & > img:first-of-type {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      & > img:nth-of-type(2) {
        position: absolute;
        left: 0px;
        bottom: 0;
      }
      & > img:last-of-type {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .m-character02 {
    background: linear-gradient(
      180deg,
      rgba(162, 158, 228, 1) 0%,
      rgba(138, 135, 193, 1) 100%
    );
    padding: 60px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    &__contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      gap: 24px;
      margin: 40px 0px 24px;
      &__top {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
        & > .mini-title {
          color: #0feffd;
        }
        & > p {
          font-size: 56px;
          font-weight: 700;
          line-height: 72px;
          color: #303056;
        }
      }
      &__middle {
        width: 100%;
        height: 162px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        & > img {
          height: 100%;
          object-fit: contain;
        }
      }
      &__bottom {
        width: 100%;
        min-height: 170px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(
          90deg,
          rgba(225, 225, 255, 0.51) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 1;
        box-shadow: 14.65px 17.58px 29.29px #0000000d;
        backdrop-filter: blur(1.2819rem) brightness(100%);
        &::after {
          content: '';
          display: flex;
          position: absolute;
          inset: 0;
          padding: 1.75px;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.23)
          );
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          pointer-events: none;
          border-radius: 8px;
        }
        & > span {
          position: absolute;
          right: 16px;
          top: -16px;
          z-index: 1;
          & > img {
            width: 37px;
            object-fit: contain;
          }
        }
        & > p {
          color: #303056;
          & > span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  .m-character03 {
    background: linear-gradient(
      180deg,
      rgba(46, 9, 22, 0.97) 0%,
      rgba(32, 10, 43, 0.69) 100%
    );
    padding: 60px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    &__contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 40px 0px 37px;
      &__top {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        & > .mini-title {
          color: #ff5b79;
        }
        & > p {
          font-size: 56px;
          font-weight: 700;
          line-height: 72px;
          color: #fff;
        }
      }
      &__middle {
        width: 100%;
        height: 198px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & > img {
          height: 206px;
          object-fit: contain;
          position: absolute;
          top: 6px;
        }
      }
      &__bottom {
        width: 100%;
        min-height: 170px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(
          90deg,
          rgba(225, 225, 255, 0.51) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 1;
        box-shadow: 14.65px 17.58px 29.29px #0000000d;
        backdrop-filter: blur(15px) brightness(100%);
        &::after {
          content: '';
          display: flex;
          position: absolute;
          inset: 0;
          padding: 1.75px;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.23)
          );
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          pointer-events: none;
          border-radius: 8px;
        }
        & > span {
          position: absolute;
          right: 16px;
          top: -16px;
          z-index: 1;
          & > img {
            width: 37px;
            object-fit: contain;
          }
        }
        & > p {
          color: #fff;
          & > span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  .m-character04 {
    background: linear-gradient(
      180deg,
      rgba(91, 135, 75, 0.97) 0%,
      rgba(12, 55, 32, 0.87) 100%
    );
    padding: 60px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    &__contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 40px 0px 32px;
      &__top {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        & > .mini-title {
          color: #fff;
        }
        & > p {
          font-size: 56px;
          font-weight: 700;
          line-height: 72px;
          color: #fff;
        }
      }
      &__middle {
        width: 100%;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 17px;
        & > img {
          height: 180px;
          object-fit: contain;
        }
      }
      &__bottom {
        width: 100%;
        min-height: 150px;
        padding: 16px;
        border-radius: 8px;
        background: linear-gradient(
          90deg,
          rgba(225, 225, 255, 0.51) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 1;
        box-shadow: 14.65px 17.58px 29.29px #0000000d;
        backdrop-filter: blur(15px) brightness(100%);
        &::after {
          content: '';
          display: flex;
          position: absolute;
          inset: 0;
          padding: 1.75px;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.23)
          );
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          pointer-events: none;
          border-radius: 8px;
        }
        & > span {
          position: absolute;
          right: 16px;
          top: -16px;
          z-index: 1;
          & > img {
            width: 37px;
            object-fit: contain;
          }
        }
        & > p {
          color: #fff;
          & > span {
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
  .m-character05 {
    background: linear-gradient(
      245deg,
      rgba(102, 126, 234, 1) 0%,
      rgba(118, 75, 162, 1) 100%
    );
    padding: 60px 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    & > ul {
      margin-top: 40px;
      margin-bottom: 32px;
      width: 100%;
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 80px;
      & > li {
        width: 100%;
        height: auto;
        &:first-of-type {
          height: 182px;
        }
        &:nth-of-type(2) {
          height: 192px;
        }
        &:last-of-type {
          height: 211px;
        }
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .character__section__expression-button {
    display: flex;
    position: relative;
    width: 169px;
    height: 7.8vh;
    max-height: 83px;
    min-height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & > span {
      position: relative;
      margin-bottom: 5px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
    & > svg {
      object-fit: contain;
      position: absolute;
      z-index: 0;
      cursor: pointer;
    }
  }
  .m-character-subTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    color: inherit;
    & > h4 {
      font-size: 28px;
      font-weight: 400;
      line-height: 38px;
      text-align: center;
    }
    & > span {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }
  .mini-title {
    padding: 4px 8px;
    background-color: rgba(29, 29, 29, 0.5);
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
.character__section__expression-button.mb {
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  min-width: 169px;
  min-height: 83px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > span {
    position: relative;
    z-index: 1;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  & > svg {
    object-fit: contain;
    position: absolute;
    z-index: 0;
    cursor: pointer;
  }
}
.m-character-sub {
  padding-top: 60px;
  background-color: #fff !important;
  width: 100%;
  height: 100%;
  flex-direction: column;
  opacity: 0;
  z-index: -999;
  animation: fadeOut 0.3s ease;
  &.active {
    opacity: 1;
    z-index: 100;
    animation: fadeIn 0.3s ease;
  }
  // &.hidden {
  //   opacity: 0;
  //   z-index: -999;
  //   animation: fadeOut 0.5s ease;
  // }
  &__title {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 14px 20px;
    & > h3 {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
  &__contents {
    width: 100%;
    &__box {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      padding: 24px 32px;
      &__title {
        height: 68px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        & > p {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          color: #fff;
          text-align: center;
          height: auto;
        }
        & > span {
          position: relative;
          width: 1px;
          height: 28px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            background-color: #fff;
            top: 0;
            right: -3px;
            border-radius: 100%;
          }
        }
      }
      &__content {
        max-width: 350px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 24px 0px;
        & > ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          margin-bottom: 24px;
          & > li {
            position: relative;
            width: 100%;
            min-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(
              90deg,
              rgba(225, 225, 255, 0.51) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            z-index: 1;
            box-shadow: 14.65px 17.58px 29.29px #0000000d;
            border-radius: 12px;
            backdrop-filter: blur(20.5104px) brightness(100%);
            &::after {
              content: '';
              display: flex;
              position: absolute;
              inset: 0;
              padding: 1.76px;
              background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0.23)
              );
              -webkit-mask:
                linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
              pointer-events: none;
              border-radius: 12px;
            }
          }
        }
      }
    }
  }
}
// 프로모션 페이지
.premium-unavailable {
  overflow-x: hidden;
  padding-top: 50px;
  width: 100%;
  height: auto;
  position: relative;
  background-color: #324539;
  box-shadow: 0 4px 24px 10px rgba(0, 0, 0, 0.15);
  min-height: 100vh;
  background-image: radial-gradient(
    88vw 300px at 62vw 330px,
    rgba(248, 245, 221, 0.7) 3%,
    rgba(248, 245, 221, 0) 100%
  );
  background-blend-mode: screen;
  & > .lamp {
    position: absolute;
    top: -23px;
    right: 4vw;
    z-index: 0;
    & > img:first-of-type {
      position: relative;
      width: 134px;
      height: 220px;
      object-fit: contain;
    }
    & > img:last-of-type {
      position: absolute;
      bottom: 25px;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 40px;
      height: 20px;
      object-fit: contain;
      transform: scaleX(1.15);
    }
  }
  &__top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 14px 20px;
    height: 56px;
    & > button {
      position: absolute;
      left: 20px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
    & > h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #fff;
    }
  }
  &__box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__content {
      padding-top: 2.5vh;
      padding-bottom: 4.5vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 11px;
      & > h2 {
        position: relative;
        z-index: 1;
        padding: 0;
        width: 100%;
        text-align: left;
        padding-top: 6.6vh;
        margin-bottom: 25px;
        & > span {
          display: block;
          font-family: 'Times New Roman' !important ;
          font-size: 48px;
          line-height: 55.2px;
          font-weight: 400;
          color: #fff;
          &:first-of-type {
            padding: 0 20px;
            margin: 0 auto;
            max-width: 345px;
            margin-bottom: 27px;
          }
          &:last-of-type {
            text-align: right;
            padding-right: 16px;
            position: relative;
            letter-spacing: 4.8px;
            max-width: 550px;
            &::after {
              content: '';
              position: absolute;
              width: calc(100% - 240px);
              height: 1px;
              background-color: #fff;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
            }
          }
        }
      }
      & > div:first-of-type {
        width: 100%;
        padding-bottom: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        & > img:first-of-type {
          position: relative;
          width: 90%;
          max-width: 500px;
          z-index: 1;
        }
        & > img:last-of-type {
          position: absolute;
          width: 100%;
          object-fit: contain;
          z-index: 0;
          left: 1%;
          bottom: 1vw;
          transform: scaleX(1.1);
        }
      }
      & > div:last-of-type {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        color: #fff;
        text-align: center;
        & > p {
          font-size: 24px;
          line-height: 27.6px;
          font-family: 'Times New Roman' !important ;
        }
        & > h3 {
          font-size: 48px;
          line-height: 55.2px;
          letter-spacing: 0.5px;
          font-family: 'Times New Roman' !important ;
        }
      }
    }
    & > .m-promotion__swiper {
      width: 100%;
      padding-bottom: 40px;
      .swiper {
        padding-bottom: 40px;
        position: relative;
        .swiper-pagination {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          & > .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background-color: rgba(255, 255, 255, 0.2);
            transition: all 0.3s ease;
            margin: 0 5px;
          }
          & > .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 10px;
            height: 10px;
            background-color: #ffffff;
          }
        }
        .swiper-slide {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

/** /// 카사요 웹 - 모바일과 동일한 스타일  */
.pull-indicator {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: rgb(252, 252, 252);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-lighter);
  transition:
    transform 0.1s ease-out,
    opacity 0.1s ease-out;
  opacity: 0;
  z-index: 10;
}

.pull-indicator img {
  transition: transform 0.1s ease-out;
}

// 캐릭터 페이지 격자무늬
.check-gradient-background {
  background:
    linear-gradient(
        to bottom,
        transparent calc((100dvw - 40px) / 10 - 1px),
        #f4f4f4 calc((100dvw - 40px) / 10 - 1px)
      )
      0 0 / 100% calc((100dvw - 40px) / 10) repeat-y,
    linear-gradient(to right, transparent 98%, #f4f4f4 20%) 0 0 / 10% 100%
      repeat-x,
    white;
}

.custom-popover-root {
  z-index: 6000 !important;
}
